$(document).ready( function() {

    // Hover/Click Navigation

    // Toggle Menu
    $(".open-menu-btn").click(function(){
      $(this).toggleClass("close");
      $(".overlay").toggleClass("open");
    });

    // Close Menu on Scroll
    $(document).on("scroll",function(){
      $(".open-menu-btn").removeClass("close");
      $(".overlay").removeClass("open");
    });

    // In case we need it later
    /*
    $('.open-menu-btn').mouseenter(function() {
            $('.overlay').addClass('open');
            $(this).addClass('close');
        }).click(function(e) {
            e.preventDefault();
            $('.overlay').removeClass('open');
            $(this).removeClass('close');
    });
    */

    // Background Switcher

    $('.global').mousemove(function(e){
        var y = e.pageX - this.offsetTop;
        var divWidth = $('.global').width();

        if ($(".overlay").is(":hover") || $(".open-menu-btn").is(":hover") || $(".close-menu-btn").is(":hover")) {
          $(".global").css('background-image','url(../img/bg04.jpg)'); // 20%
          } else {
              if(y < divWidth*0.3){
                $(".global").css('background-image','url(../img/bg02.jpg)'); // 33%
              } else if(y < divWidth*0.66 && y >= divWidth*0.33){
                $(".global").css('background-image','url(../img/bg03.jpg)'); // 66%
              } else if(y < divWidth && y >= divWidth*0.66){
                $(".global").css('background-image','url(../img/bg04.jpg)'); // 100%
              }
          }

    });

});
